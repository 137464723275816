<style>
.c-link {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: bold;
  letter-spacing: .6px;
  color: #bfc8e2 !important;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -o-user-select: none;
  user-select: none;
}
.c-server-link {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: .5px;
  color: #bfc8e2 !important;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -o-user-select: none;
  user-select: none;
}
.c-link:hover {
  color: #a7b0c9 !important;
}
@media (min-width: 1366px) {
  .c-links {
    margin-left: 5rem;
    margin-top: 2rem;
  }
}
@media (max-width: 1366px) {
  .c-links {
    margin-top: 2rem;
  }
}
.c-component-col {
  margin-top: 2rem;
}
.c-link.active {
  color: white !important;
}
.mobile-menu {
  width: 100vw;
  height: 3rem;
  background-color: #545a6d;
  display: flex;
  overflow-x: scroll;
  margin-bottom: 1rem;
}
.mobile-container {
  height: 3rem;
  min-width: 4rem;

  text-align: center;

  padding-top: 0.75rem;
  padding-bottom: 2rem;

  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;
  color: white;
  white-space: nowrap;
}
.mobile-container-last {
  min-width: 1px !important;
}
.mobile-container.active {
  border-bottom: 3px solid var(--primary);
}
.desktop-container {
  width: 100%;
  padding-right: unset !important;
  padding-left: unset !important;
  margin-left: auto;
  margin-right: auto;
}
</style>
<script>
import { VclCode } from 'vue-content-loading';

import { HalfCircleSpinner, CirclesToRhombusesSpinner } from 'epic-spinners'
import {AccountStatus, Game} from '@/enums';
import CopyableText from "@/components/CopyableText";

export default {
  props: ['server_id', 'permissions', 'features', 'console', 'server', 'worker'],
  components: {
    CopyableText,
    HalfCircleSpinner,
    //Generic: () => import('@/components/lazy/servers/dashboard/games/Generic'),
    MUnavailable: () => import('@/components/lazy/servers/dashboard/games/Unavailable'),
    // Desktop DayZ
    DDayZ: () => import('@/components/lazy/servers/dashboard/games/desktop/DayZ'),
    DArmA: () => import('@/components/lazy/servers/dashboard/games/desktop/ArmA'),
  },
  methods: {
    changeTab(tab, options) {
      this.currentTab = tab;
    },
    tabChangeRequest(tab) {
      this.currentTab = tab;
    },
    changeMobileTab(tab, options) {
      this.currentTab = tab;
    },
    getData() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/structure`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            if(response.status === 403) this.isUnauthorized = true;
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.game = data.server.game;
          this.$emit('identifierLoaded', data.server.identifier, data.permissions.configuration);
          /*
          Ready event invokes rendering of the sub-components which depend on the above data to be available.
          Sometimes, the proxy setter is too slow and we end up with a race condition, resulting in a failed page load.
          Adding a slow delay fixes this.
          */
          setTimeout(() => {
            this.ready = true;
          }, 1);
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    handleError(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    actionToggleFullpage() {
      this.toggleOverlay();
    },
    actionEnableFullpage(text, color, spinner) {
      this.overlayText = text;
      this.overlayTextColor = color;
      this.overlaySpinner = spinner;
    },
    toggleOverlay: function() {
      this.overlayEnabled = !this.overlayEnabled;
    },
    actionSupport: function () {
      return this.game === Game.DAYZ ? true : false;
    }
  },
  mounted() {
    try {
      // Should this fail for whatever reason, fall back
      let element = document.getElementsByClassName('c-mobile')[0];
      if(window.getComputedStyle(element, null).display === 'block') {
        this.is_mobile = true;
      }
      else this.is_mobile = false;
    } catch(e) {
      this.is_mobile = null;
    }
    this.getData();
  },
  created() {
    this.options.worker = this.worker;
    this.options.server = this.server;
    this.options.console = this.console;
    this.options.features = this.features;
    this.options.permissions = this.permissions;
  },
  watch: {
    ready() {
      switch(this.game) {
        case Game.DAYZ: {
          this.currentTab = 'DDayZ';
          break;
        }
        case Game.ARMA2: {
          this.currentTab = 'DArmA';
          break;
        }
        case Game.ARMA3: {
          this.currentTab = 'DArmA';
          break;
        }
        default: {
          this.currentTab = 'GENERIC';
        }
      }
    }
  },
  data() {
    return {
      is_mobile: null,
      currentTab: null,
      options: {
        permissions: this.permissions,
        features: this.features,
        server: this.server,
        console: this.console,
        worker: this.worker
      },
      ready: false,
      error: false,
      isUnauthorized: false,
      isPrivileged: false,
      overlayEnabled: false,
      overlayText: '',
      overlayTextColor: '',
      overlaySpinner: false
    }
  }
};
</script>

<template>
  <div :class="{'fullpage-overlay-enabled': overlayEnabled}">
    <div class="fullpage-overlay">
      <div class="fullpage-overlay-content">
        <h3 :class="'text-'+overlayTextColor">
          <half-circle-spinner
              v-if="overlaySpinner"
              :animation-duration="1200"
              :size="24"
              class="align-middle d-inline-block mr-2"
          />
          {{ overlayText }}
        </h3>
      </div>
    </div>
    <template v-if="error">
      <div class="row mt-4">
        <div class="col justify-content-center">
          <div  class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <i class="fas fa-do-not-enter text-danger info-component-icon"></i>
            </div>
            <div class="row" style="margin-top: 20px;">
              <div class="col-lg-12" v-if="isUnauthorized">
                <h3 v-b-tooltip.hover title="Bad. Bonk." class="text-uppercase text-danger">{{$t('error.permissions.title')}}</h3>
                <h5 class="text-muted">{{$t('error.permissions.message')}}</h5>
                <h6 class="text-muted">{{$t('error.permissions.details')}}</h6>
              </div>
              <div class="col-lg-12" v-else>
                <h3 v-b-tooltip.hover title="Bad. Bonk." class="text-uppercase text-danger">{{$t('error.server.generic.title')}}</h3>
                <h5 class="text-muted">{{$t('error.server.generic.component')}}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <!-- Desktop -->
      <div class="c-desktop">
        <div class="" v-if="is_mobile === false && ready" >
          <template v-if="currentTab === null">
            <div class="row mt-4">
              <div class="col-lg-12 justify-content-center">
                <div  class="info-component text-center align-middle mt-auto mb-auto">
                  <div class="info-component-space">
                    <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <component
                ref="component"
                v-if="ready === true"
                @actionEnableFullpage="actionEnableFullpage"
                @actionToggleFullpage="actionToggleFullpage"
                @tabChangeRequest="tabChangeRequest"
                :options="options"
                :server_id="server_id"
                :is_mobile="false"
                v-bind:is="currentTab"
            >
            </component>
          </template>
        </div>
      </div>
      <!-- Desktop END -->

      <!-- MOBILE -->
      <div class="c-mobile">
        <div v-if="is_mobile === true && ready">
          <div class="mobile-content">

            <template v-if="currentTab === null">
              <div class="row mt-4">
                <div class="col-lg-12 justify-content-center">
                  <div  class="info-component text-center align-middle mt-auto mb-auto">
                    <div class="info-component-space">
                      <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="mobile-content">
                <component
                    ref="component"
                    v-if="ready === true"
                    @actionEnableFullpage="actionEnableFullpage"
                    @actionToggleFullpage="actionToggleFullpage"
                    @tabChangeRequest="tabChangeRequest"
                    :options="options"
                    :server_id="server_id"
                    :is_mobile="true"
                    v-bind:is="currentTab">
                </component>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div v-if="is_mobile === null">
        <div class="row" style="margin-top: 70px;">
          <div class="col-lg-12">
            <div class="card bg-danger">
              <div class="card-body p-2">
                <h5 class="mt-0 mb-1 text-white">
                  <i class="fas fa-ban mr-1"></i>
                  {{ $t('profile.errors.viewport.title') }}
                </h5>
                <p class="card-text text-white">
                  {{ $t('profile.errors.viewport.message') }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-12 justify-content-center">
            <div  class="info-component text-center align-middle mt-auto mb-auto">
              <div class="info-component-space">
                <half-circle-spinner :animation-duration="900" :size="64" class="align-middle d-inline-block info-component-icon"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
